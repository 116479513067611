import router from '@/router'
import { useEntityStore } from '@/stores/entity'
import _ from 'lodash'
import moment from 'moment-timezone'
import type { PiniaCustomStateProperties } from 'pinia'

// 👉 IsEmpty
export const isEmpty = (value: unknown): boolean => {
  if (value === null || value === undefined || value === '')
    return true

  return !!(Array.isArray(value) && value.length === 0)
}

// 👉 IsNullOrUndefined
export const isNullOrUndefined = (value: unknown): value is undefined | null => {
  return value === null || value === undefined
}

// 👉 IsEmptyArray
export const isEmptyArray = (arr: unknown): boolean => {
  return Array.isArray(arr) && arr.length === 0
}

// 👉 IsObject
export const isObject = (obj: unknown): obj is Record<string, unknown> =>
  obj !== null && !!obj && typeof obj === 'object' && !Array.isArray(obj)

export const isToday = (date: Date) => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

// get key within object else return default
export const get = function (obj: ({ entity: any } & PiniaCustomStateProperties<{ entity: any }>) | ({ error: null; isFetching: boolean; data: any } & PiniaCustomStateProperties<{ error: null; isFetching: boolean; data: any }>) | ({ error: string; isFetching: boolean; list: never[]; stats: any; meta: { total: number } } & PiniaCustomStateProperties<{ error: string; isFetching: boolean; list: never[]; stats: any; meta: { total: number } }>), key: string, empty: never[] | null) {
  return (
    key.split('.').reduce((o: { [x: string]: any } | null, x: string | number) => {
      return (typeof o === 'undefined' || o === null) ? o : o[x]
    }, obj) || empty
  )
}

/**
 * If the charity in localStorage is different than the charity in the URL, redirect to the dashboard
 * @param route - The current route object.
 * @returns the current route.
 */
export const verifyCharityUrlState = (route: any) => {
  const entity = useEntityStore()
  let entityType = entity.type

  if (!entityType && route?.path) {
    if (route.path.includes('administrator')) {
      entityType = 'ribbon'
      entity.set({ ribbon: true })
    }
    else if (route.params.sponsor_id) {
      entityType = 'sponsor'
      entity.set({ uuid: route.params.sponsor_id })
    }
    else if (route.params.charity_id) {
      entityType = 'charity'
      entity.set({ uuid: route.params.charity_id })
    }
  }

  // if route has resource guard
  // if resource is public continue
  // else if resource is guarded, check guard and continue

  if (route.meta?.resource) {
    if (route.meta.resource.toLowerCase() === 'public')
      return

    if (route.meta.resource.toLowerCase() === 'ribbon') {
      entityType = 'ribbon'
      entity.set({ ribbon: true })

      return
    }

    if (
      (entityType && route.meta.resource.toLowerCase() !== entityType)
      || (
        route.meta.resource !== 'Public'
        && route.name !== 'verify-login'
        && route.name !== 'profile-settings'
        && route.name !== 'dashboard'
        && route.name !== 'dashboard-selection'
        && !route.path.includes('application')
        && !route.path.includes('welcome')
        && !entityType
      )
    )
      return { name: 'dashboard' }
  }
}

/**
 * It returns the text color that contrasts with the given background color.
 * @param color - The color you want to get the text color for.
 * @param [isRGB=false] - If the color is in RGB format, set this to true.
 */
export const getTextColor = function (color = '#ffffff', isRGB = false) {
  let rgb = [255, 255, 255]
  if (isRGB) {
    rgb = color.split(',')
  }
  else {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?([a-f\d]{2})$/i.exec(
      color,
    )

    rgb = [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ]
  }

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114)
      / 1000,
  )

  return brightness > 125 ? 'black' : 'white'
}

/**
 * It takes a hex color and an alpha value and returns a rgba color
 * @param color - The hex color code.
 * @param alpha - The desired opacity of the color.
 * @returns the color in rgba format.
 */
export const hexColorOpacity = function (color, alpha) {
  const r = parseInt(color.slice(1, 3), 16)
  const g = parseInt(color.slice(3, 5), 16)
  const b = parseInt(color.slice(5, 7), 16)

  if (alpha)
    return `rgba(${r}, ${g}, ${b}, ${alpha})`

  else
    return `rgb(${r}, ${g}, ${b})`
}

/**
 * It takes a date object and returns a string in the format of "Month Day, Year"
 * @param date - The date object to format
 * @returns The month, day, and year of the date.
 */
export const simpleDateFormat = (rawDate: any, ignoreTimezone = false) => {
  if (!rawDate)
    return null

  if (ignoreTimezone)
    return moment.utc(rawDate).format('MMM D, YYYY')

  return moment.utc(rawDate).local().format('MMM D, YYYY')
}

/**
 * It takes a date object and returns a string in the format of "Month Year"
 * @param date - The date object to format
 * @returns The month and year of the date.
 */
export const simpleMonthYearFormat = (rawDate: any, ignoreTimezone = false) => {
  if (!rawDate)
    return null

  if (ignoreTimezone)
    return moment.utc(rawDate).format('MMM YYYY')

  return moment.utc(rawDate).local().format('MMM YYYY')
}

/**
 * It takes a date object and number of days to add and returns the calculated date.
 * @param date - The starting date
 * @param days - The number of days you want to add to the date
 * @returns The adjusted date.
 */
export const addDays = (date: Date | string, days: number) => {
  const result = new Date(date)

  result.setDate(result.getDate() + days)

  return new Date(result)
}

/**
 * It takes a date object and returns a string in the format of "Month Day, Year"
 * @param date - The date object to format
 * @returns The month, day, and year of the date.
 */
export const simpleDatetimeFormat = (date: any, local = true) => {
  if (!date)
    return null

  const time = moment.utc(date)

  if (local)
    time.local()

  return time.format('MMM D, YYYY h:mm A')
}

/**
 * If the value of a key is null, delete the key.
 * @param fullObj - The full object that you want to clean.
 * @returns the object with the null values removed.
 */
export const cleanDictionary = function (fullObj: any) {
  const obj = { ...fullObj }

  Object.keys(obj).forEach(key => {
    if (isEmpty(obj[key]))
      delete obj[key]
  })

  return obj
}

/**
 * It takes a number and returns a string formatted as US currency
 * @param val - The value to format
 * @returns A function that takes a value and returns a formatted string.
 */
export const currencyFormat = (val: any, decimals = 2, showSymbol = true) => {
  if (!val)
    return '$0.00'

  val = val.toString().replace(/,/g, '')

  if (Math.abs(parseFloat(val)) < 0.01)
    decimals = 4

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  })

  let result = formatter.format(val)

  if (!showSymbol)
    result = result.replace(/^\$/, '') // Removes the dollar sign from the beginning of the string

  return result
}

export const months = [
  { text: 'January', value: 1 },
  { text: 'February', value: 2 },
  { text: 'March', value: 3 },
  { text: 'April', value: 4 },
  { text: 'May', value: 5 },
  { text: 'June', value: 6 },
  { text: 'July', value: 7 },
  { text: 'August', value: 8 },
  { text: 'September', value: 9 },
  { text: 'October', value: 10 },
  { text: 'November', value: 11 },
  { text: 'December', value: 12 },
]

/* It's a function that takes a card object and returns an object with a limit period and amount. */
export const parseLimit = (card: any) => {
  const limits = {
    daily: 'daily',
    monthly: 'monthly',
    weekly: 'weekly',
    yearly: 'yearly',
    all_time: 'all time',
  }

  const lims = []
  if (card.limits) {
    for (const [k, v] of Object.entries(limits)) {
      if (card.limits[`${k}_limit`]) {
        lims.push({
          period: v,
          amount: currencyFormat(card.limits[`${k}_limit`] / 100),
          available: currencyFormat(
            (card.limits[`${k}_limit`] - card.limits[`${k}_usage`]) / 100,
          ),
        })
      }
    }
  }

  if (!lims.length)
    lims.push({ period: 'daily', amount: '$2,000', available: '$2,000' })

  return lims
}

/**
 * It takes a key, a value, and a route, and returns a new route with the query parameter updated.
 * @param key - the key of the query param you want to update
 * @param val - the value of the query param
 * @param route - the route you want to update the query params on
 */

export const updateQueryParams = (key: string | number, val: string | Array<string>) => {
  const q = { ...router.currentRoute.value.query }

  if (Array.isArray(val)) {
    if (!isEmptyArray(val)) {
      q[key] = val
    } else {
      delete q[key]
    }
  } else {
    if (!isEmpty(val)) {
      q[key] = val
    } else {
      delete q[key]
    }
  }

  router.push({
    path: router.currentRoute.value.path,
    query: q,
  })
}

/**
 * It takes a string of numbers and returns a formatted phone number
 * @param phoneNumberString - The phone number string to format.
 * @returns the phone number in the format of (xxx) xxx-xxxx
 */
export const formatPhoneNumber = function (phoneNumberString) {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return null
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const getObjectDiff = (obj1, obj2) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    }
    else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)

      result.splice(resultKeyIndex, 1)
    }

    return result
  }, Object.keys(obj2))
}

/**
 * It takes a dictionary of filters and returns a query string that can be used to filter donors
 * @param data - {
 * @returns A query string
 */
export const buildDonorQuery = data => {
  const filters = {
    type: data.type,
    search: data.search,
    page: data.page,
    itemsPerPage: data.itemsPerPage,
    sort_direction: data.sort_direction,
    file_type: data.file_type,
    year: data.year,
    category: data.category,
    missing_receipts: data.missing_receipts,
    start_date: data.start_date,
    charity_uuid: data.charity_uuid,
    low_amount: data.low_amount,
    high_amount: data.high_amount,
    sort_by: data.sort_by,
  }

  const qs = new URLSearchParams(cleanDictionary(filters))
  if (data.grants) {
    for (const g of data.grants)
      qs.append('grant_id_array[]', g)
  }
  if (data.campaigns) {
    for (var c of data.campaigns)
      qs.append('campaign_id_array[]', c)
  }
  if (data.forms) {
    for (var c of data.forms)
      qs.append('embed_form_id_array[]', c)
  }

  return qs
}

export const closeDrawer = (key: string | string[]) => {
  const route = router.currentRoute.value
  const q = JSON.parse(JSON.stringify(route.query))

  if (Array.isArray(key)) {
    key.forEach(k => {
      delete q[k]
    })
  }
  else {
    delete q[key]
  }
  if (key === 'upload_document') {
    delete q.year
    delete q.template
    delete q.month
    delete q.type
  }
  router.push({ path: route.path, query: { ...q } })
}

/**
 * Format object to CSV ready
 * @param results - {
 * @returns A string
 */
export const convertResultsToCSV = (results: any) => {
  const headers = Object.keys(results[0])

  const rows = results.map((result: any) => {
    return headers.map(header => {
      const value = result[header]

      return (typeof value === 'string' && value.includes(',')) ? `"${value}"` : value
    }).join(',')
  })

  return [headers.join(','), ...rows].join('\n')
}

export const capitalize = (text: string) => {
  return text.toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

/**
 * It takes a dictionary of filters and returns a query string that can be used to filter transactions
 * @param data - {
 * @returns A query string
 */
export const buildTransactionQuery = (data: any) => {
  const { allocation_status, budgets, grants, campaigns, forms, months, min_expenses, donors, charity_models, type, search, page, itemsPerPage, sort_direction, file_type, year, category, missing_receipts, start_date, end_date, charity_uuid, sort_by, status, report_type, credits, debits } = data

  const filters = {
    search,
    page,
    itemsPerPage,
    sort_direction,
    file_type,
    year,
    category,
    missing_receipts,
    start_date,
    end_date,
    charity_uuid,
    sort_by,
    status,
    report_type,
    min_expenses,
    credits,
    debits,
  }

  const qs = new URLSearchParams(cleanDictionary(filters))

  const append = (key: string, value: any) => {
    if (Array.isArray(value))
      value.forEach(item => qs.append(key, item))
    else if (value)
      qs.append(key, value)
  }

  append('allocation_status[]', allocation_status)
  append('type[]', type)
  append('budget_id_array[]', budgets)
  append('grant_id_array[]', grants)
  append('campaign_id_array[]', campaigns)
  append('embed_form_id_array[]', forms)
  append('month_id_array[]', months)
  append('donor_id_array[]', donors)
  append('charity_model_array[]', charity_models)

  return qs
}

export const buildBillPayQuery = (data: any) => {
  const { search, page, itemsPerPage, sort_direction, charity_uuid, sort_by, status } = data

  const filters = {
    search, page, itemsPerPage, sort_direction, charity_uuid, sort_by, status
  }

  const qs = new URLSearchParams(cleanDictionary(filters))

  return qs
}

export const buildReimbursementQuery = (data: any) => {
  const { search, page, itemsPerPage, sort_direction, charity_uuid, sort_by, status } = data

  const filters = {
    search, page, itemsPerPage, sort_direction, charity_uuid, sort_by, status
  }

  const qs = new URLSearchParams(cleanDictionary(filters))

  return qs
}

export const handleTransactionExport = (data: any, type: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = downloadUrl
  link.setAttribute(
    'download',
    `ribbon-${type ?? 'transaction'}-${moment().format('MM-DD-YY-HH:mm:ss')}.csv`,
  )
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const handleProgram1099DataExport = (data: any) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = downloadUrl
  link.setAttribute(
    'download',
    `ribbon-program-1099-data-${moment().format('MM-DD-YY-HH:mm:ss')}.csv`,
  )
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const handleUrlDownload = async (url: string, fileName: string) => {
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', fileName)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const handleSageDataExport = (data: any, fileName: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = downloadUrl
  link.setAttribute(
    'download',
    fileName,
  )
  document.body.appendChild(link)
  link.click()
  link.remove()
}
